import React from "react"
import { graphql } from "gatsby"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { Container, Row, Col, Accordion } from "react-bootstrap"
import styled from "styled-components"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import InsuranceDescription from "../components/insuranceDescription"
import CallIcon from "../components/callIcon"
import StockImage from "../components/stockImage"
import QuoteForm from "../components/quoteForm"

import InsuranceImage from "../images/stock-image-21.jpg"
import InsuranceImageMobile from "../images/stock-image-21-mobile.jpg"
import InsuranceImageTablet from "../images/stock-image-21-tablet.jpg"
import Doubt from "../images/stock-image-22.jpg"
import Banner1 from "../images/stock-image-23.jpg"
import Banner2 from "../images/stock-image-24.jpg"
import Banner3 from "../images/stock-image-25.jpg"

const Banner = styled.img`
  width: 100%;
  border-radius: 0 0 15px 15px;
`

const FloatingTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 6rem;
  transform: translateY(-50%);
  & > h1 {
    font-size: 4.875rem !important;
    margin: 0;
  }
  @media (max-width: 1024px) {
    left: 4rem;
    & > h1 {
      font-size: 3rem !important;
    }
  }
  @media (max-width: 767px) {
    left: 0.75rem;
    & > h1 {
      font-size: 1.75rem !important;
    }
  }
`

const ObamacareInsurance = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="Obamacare" />

      <InsuranceDescription
        title={t("obamacare_title")}
        subtitle={t("obamacare_subtitle")}
        content={t("obamacare_description")}
        actions={
          <>
            <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary btn-sm">
              <CallIcon /> {APREMIA_PHONE_FORMATTED}
            </a>
            <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
              {t("we_call_you")}
            </a>
          </>
        }
        image={InsuranceImage}
        imageMobile={InsuranceImageMobile}
        imageTablet={InsuranceImageTablet}
      />

      <div className="bg-white py-md-4 py-lg-6">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col xs="12" lg className="text-end">
              <StockImage src={Doubt} />
            </Col>
            <Col
              xs="12"
              lg
              className="d-flex flex-column justify-content-center equalize-padding-x px-md-4 px-lg-6 pb-4 pb-md-0"
            >
              <h3>{t("obamacare_title_2")}</h3>
              <ul className="ul ul-normal">
                {new Array(6).fill(undefined).map((_, index) => (
                  <li key={index} className="mb-2">
                    {t(`oc_desc_${index}`)}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="py-5 py-lg-6">
        <Container>
          <h1 className="pb-lg-4">{t("obamacare_qualify_title")}</h1>
          <div className="px-lg-9">
            {new Array(3).fill(undefined).map((_, index) => (
              <div className="step" key={index}>
                <div className="step-badge">{index + 1}</div>
                <h3 className="step-heading m-0 pb-4 pb-md-0">
                  {t(`obamacare_q${index}`)}
                </h3>
              </div>
            ))}
          </div>
        </Container>
      </div>

      {/* <div className="bg-white pb-5">
        <Container className="gx-0 gx-md-3">
          <Banner src={Banner1} />
        </Container>

        <Container>
          <Row className="mt-4 mt-md-5 mb-4">
            <Col>
              <h1>{t("obamacare_title_3")}</h1>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md>
              <p className="fw-light">
                <Trans i18nKey="obamacare_cov0">
                  <b>
                    Un Período Especial de Inscripción debido a un cambio de
                    vida
                  </b>
                  <br />
                  Ciertos eventos de vida, como perder cobertura médica,
                  mudarse, casarse, tener un bebé o adoptar un hijo, pueden
                  calificarlo para inscribirse o cambiar su plan del Mercado
                  fuera del Período de Inscripción Abierta anual.
                </Trans>
              </p>

              <p className="fw-light">
                <Trans i18nKey="obamacare_cov1">
                  <b>
                    Medicaid – un programa de seguro que brinda cobertura médica
                    gratuita o de bajo costo
                  </b>
                  <br />
                  Las personas con bajos ingresos, incluidos individuos,
                  familias y niños, mujeres embarazadas, adultos mayores y
                  personas con incapacidades, pueden calificar. Si es elegible,
                  puede solicitar en cualquier momento.
                </Trans>
              </p>
            </Col>

            <Col xs="12" md>
              <p className="fw-light">
                <Trans i18nKey="obamacare_cov2">
                  <b>
                    El Programa de Seguro Médico para Niños (CHIP) – un programa
                    de seguro que brinda cobertura médica gratuita o de bajo
                    costo para niños en familias de ciertos ingresos.
                  </b>
                  <br />
                  CHIP ayuda a los niños de hogares que ganan demasiado dinero
                  para calificar para Medicaid, pero no lo suficiente para
                  comprar un seguro privado. En algunos estados, CHIP cubre a
                  las mujeres embarazadas.
                </Trans>
              </p>
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* <div className="pb-6">
        <Container className="gx-0 gx-md-3">
          <Banner src={Banner2} />
        </Container>

        <Container>
          <h1 className="mt-4 mt-md-5 mb-4">{t("obamacare_title_4")}</h1>
          <div className="px-md-6 px-lg-8">
            <Accordion flush alwaysOpen>
              {new Array(5).fill(undefined).map((_, index) => (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header>
                    <h3 className="d-none d-md-block m-0">
                      {t(`obamacare_c${index}_title`)}
                    </h3>
                    <h4 className="d-md-none m-0">
                      {t(`obamacare_c${index}_title`)}
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body className="render-tags">
                    <div className="pb-5 pe-md-6 fw-light">
                      {t(`obamacare_c${index}_description`)}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
      </div> */}

      <div className="bg-white pb-6">
        <Container className="gx-0 gx-md-3 position-relative">
          <Banner src={Banner3} />
          <FloatingTitle>
            <h1>
              <Trans i18nKey="faq2">
                Preguntas
                <br />
                frecuentes
              </Trans>
            </h1>
          </FloatingTitle>
        </Container>

        <Container>
          <div className="mt-4 mt-md-5 px-md-6 px-lg-8">
            <Accordion flush alwaysOpen>
              {new Array(4).fill(undefined).map((_, index) => (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header>
                    <h3 className="d-none d-md-block m-0">
                      {t(`obamacare_faq${index}_title`)}
                    </h3>
                    <h4 className="d-md-none m-0">
                      {t(`obamacare_faq${index}_title`)}
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body className="render-tags">
                    <div className="pb-5 pe-md-6 fw-light">
                      {t(`obamacare_faq${index}_description`)}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
      </div>

      <QuoteForm bordered />
    </Layout>
  )
}

export default ObamacareInsurance

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
